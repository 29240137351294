<template>
  <navbar>
    <h6 class="title-navbar">
      Syarat & Ketentuan
    </h6>
  </navbar>

    <div class="container term__condition-wrapper">
  <div v-if="loading">
      <div class="lazy-loading-content image-container">
      <Skeleton width="100" height="274"/>
      </div>
    </div>
    <div class="content" v-if="!loading && content != null" v-html="content">
    </div>
    <div class="content" v-if="!loading && content == null">
      <div class="">
    <div class="title">
      <h6>Ketentuan Umum</h6>
    </div>
    <div class="content">
      <p>
        <strong>A. Definisi</strong>
      </p>
      <p>Dalam Syarat dan Ketentuan ini yang dimaksud dengan:</p>
      <p>
        <strong>1.1 Akun</strong>
        &nbsp;adalah suatu pengaturan (arrangement) antara Penyedia Platform dengan Pengunjung
        Platform atau Pengguna Platform berdasarkan mana Pengunjung Platfrom atau Pengguna Platform
        diberikan akses oleh Penyedia Platform terhadap fitur-fitur Platform setelah melakukan
        pendaftaran data pribadi, nama pengguna (username) dan kata sandi (password).
      </p>
      <p>
        <strong>1.2 BANI</strong>
        &nbsp;adalah singkatan dari Badan Arbitrase Nasional Indonesia, suatu lembaga arbitrase yang
        berbentuk perkumpulan berbadan hukum melalui akta No.7 tanggal 25 September 2019, yang
        dibuat di hadapan Bapak Ramadhan Muawad, SH, Notaris di Kab Bandung Barat,yang telah
        mendapat persetujuan dari Menteri Kehakiman dan Hak Asasi Manusia Republik Indonesia dengan
        surat keputusan No.AHU-0014082.AH.01.04. Tahun 2019 berikut perubahannya.
      </p>
      <p>
        <strong>1.3 Penerima Manfaat </strong>
        &nbsp;adalah
      </p>
      <ol>
        <li>
          <p>Pihak yang merangkap sebagai Lembaga dan terdaftar dalam Platform; atau</p>
        </li>
        <li>
          <p>
            Pihak lain selain lembaga yang adalah individu, kelompok, badan usaha atau badan hukum,
            yang menerima manfaat (beneficiary) atas Dana dari suatu program.
          </p>
        </li>
      </ol>
      <p>
        <strong>1.4 Dana </strong>
        &nbsp;adalah sumbangan atau donasi (donation) yang dinyatakan dalam mata uang Rupiah.
      </p>
      <p>
        <strong>1.5 Program </strong>
        &nbsp;adalah suatu usaha Penggalangan Dana untuk maksud dan tujuan tertentu, termasuk, namun
        tidak terbatas pada, kategori bantuan medis, atlet dan fasilitas olahraga, infrastruktur,
        pendidikan dan beasiswa, difabel, umrah dan haji, panti asuhan dan rumah ibadah, baik dengan
        maupun tanpa janjian imbalan (reward).
      </p>
      <p>
        <strong>1.6 Lembaga </strong>
        &nbsp;adalah kelompok, badan usaha atau badan hukum yang menggunakan fasilitas Penggalangan
        Dana pada Platform untuk sebuah Program tertentu dan bertanggung jawab atas Pelaksanaan
        program yang bersangkutan.
      </p>
      <p>
        <strong>1.7 Donatur </strong>
        &nbsp;adalah individu, kelompok, badan usaha maupun badan hukum yang melakukan pendaftaran
        ke Platform untuk mendukung Program dengan menyalurkan Dana.
      </p>
      <p>
        <strong>1.8 Fundraiser </strong>
        &nbsp;adalah seseorang atau organisasi yang menyatakan dukungan terhadap suatu program yang
        dibuat oleh lembaga dalam bentuk pembuatan halaman program baru yang terhubung dengan
        program utama. Dana yang masuk dari halaman fundraiser akan masuk di program utama.
      </p>
      <p>
        <strong>1.9 Konten </strong>
        &nbsp;adalah segala jenis materi dan/atau muatan yang dibuat dan/atau diunggah ke dalam
        Platform secara mandiri oleh Pengguna Platform (user generated content) dan bukan oleh
        Penyedia Platform.
      </p>
      <p>
        <strong>2.0 Konten Yang Dilarang </strong>
        &nbsp;adalah segala jenis materi dan/atau muatan yang melanggar ketentuan peraturan
        perundang-undangan.
      </p>
      <p>
        <strong>2.1 Laporan </strong>
        &nbsp;adalah pemberitahuan yang disampaikan oleh Pengunjung Platform dan/atau Pengguna
        Platform kepada Penyedia Platform tentang telah atau sedang atau diduga akan terjadinya
        peristiwa pelanggaran Syarat dan Ketentuan.
      </p>
      <p>
        <strong>2.2 Pelaksanaan Program </strong>
        &nbsp;adalah tahap perwujudan atau realisasi dari maksud dan tujuan sebuah program dengan
        menggunakan hasil Penggalangan Dana yang bersangkutan secara bertanggung jawab.
      </p>
      <p>
        <strong>2.3 Pencairan Dana </strong>
        &nbsp;adalah tindakan Penyedia Platform mengalihkan (transfer) Dana yang terkumpul dari:
        Atas nama Lembaga atau Penerima Manfaat, ke rekening tujuan yang telah ditunjuk Lembaga atau
        Penerima Manfaat pada saat pendaftaran yang dilakukan atas permohonan (request) Lembaga atau
        Penerima Manfaat.
      </p>
      <p>
        <strong>2.4 Penggalangan Dana </strong>
        &nbsp;adalah proses pengumpulan Dana dari masyarakat dalam rangka pembangunan di bidang
        kesejahteraan sosial, mental, agama, kerohanian, kejasmanian dan kebudayaan.
      </p>
      <p>
        <strong>2.5 Pengguna Platform </strong>
        &nbsp;terdiri dari Lembaga , Lembaga dan/atau Penerima Manfaat, baik secara langsung maupun
        tidak langsung, sebagaimana berlaku.
      </p>
      <p>
        <strong>2.6 Pengunjung Platform </strong>
        &nbsp;adalah pihak yang mengakses, membuka, dan memperoleh informasi dari Platform.
      </p>
      <p>
        <strong>2.8 Pengaduan </strong>
        &nbsp;adalah Laporan yang disertai permintaan kepada Penyedia Platform untuk memeriksa
        Pengguna Platform yang telah atau sedang atau diduga melakukan pelanggaran Syarat dan
        Ketentuan.
      </p>
      <p>
        <strong>2.9 Penyedia Platform </strong>
        &nbsp;adalah YAYASAN AMAZING SEDEKAH INDONESIA yang menyelenggarakan program Penggalangan
        Dana dalam jaringan (online crowdfunding) berdasarkan Keputusan Menteri Sosial Republik
        Indonesia No.1192/HUK-PS/2017 tentang Izin Penyelenggaraan Pengumpulan Sumbangan Kepada
        Yayasan Bersama Beramal Sholeh Di Kab Bandung Barat tanggal 25 September 2019.
      </p>
      <p>
        <strong>3.0 Syarat dan Ketentuan </strong>
        &nbsp;adalah syarat dan ketentuan penggunaan Platform yang ditetapkan dan dapat diubah
        sewaktu-waktu oleh Penyedia Platform serta mengikat bagi Pengunjung Platform dan/atau
        Pengguna Platform.
      </p>
      <p>
        <strong>3.1 Update </strong>
        &nbsp;adalah fitur yang terdapat pada halaman program yang difungsikan untuk setiap lembaga
        agar dapat memberikan pemberitahuan kepada seluruh donatur melalui email secara otomatis
        mengenai keadaan terbaru program, penggunaan dana maupun hal lainnya.
      </p>
      <p>
        <strong>3.2 Verifikasi </strong>
        &nbsp;adalah tindakan pemeriksaan kebenaran dan kelengkapan yang dilakukan oleh Penyedia
        Platform terhadap Akun, Konten dan/atau Pencairan Dana yang didaftarkan, diunggah dan/atau
        dimohonkan oleh Pengguna Platform, atau untuk keperluan lainnya berdasarkan diskresi penuh
        Penyedia Platform.
      </p>
      <p>
        <strong>B. Pengungkapan Secara Sukarela</strong>
      </p>
      <p>
        Segala pengungkapan (disclosure) oleh Pengunjung Platform atau Pengguna Platform dalam
        bentuk pertanyaan, pernyataan, komentar,ide, kritik, saran atau informasi pada Platform,
        yang bukan merupakan pengungkapan yang disyaratkan atau diminta oleh Penyedia Platform,
        adalah pengungkapan yang dibuat secara sukarela (voluntary disclosure) dan sepenuhnya
        merupakan tanggung jawab dari Pengunjung Platform atau Pengguna Platform yang bersangkutan
      </p>
      <p>
        <strong>C. Posisi AmazingSedekah</strong>
      </p>
      <p>
        AmazingSedekah.com Bukanlah Broker/Lembaga Penyalur Amal/Lembaga Keuangan/Kreditor.
        AmazingSedekah.com merupakan platform untuk memfasilitasi transaksi donasi antara Lembaga
        dan donatur. AmazingSedekah tidak bertanggung jawab atas pelaksanaan, atau informasi yang
        disediakan oleh Lembaga, donatur, penerima manfaat atau pengguna lainnya. AmazingSedekah
        dengan ini melepaskan semua tanggung jawab dalam hal tersebut selama diizinkan oleh hukum
        yang berlaku di Indonesia.
      </p>
      <p>
        Semua informasi di dalam konten program yang disediakan oleh AmazingSedekah merupakan bagian
        dari pemberitahuan, kami tidak menjamin keakuratan, kelengkapan, ketepatan waktu atau
        kebenaran dari konten yang dibuat oleh Lembaga. Maka, Anda mengetahui bahwa informasi dan
        konten yang ada pada halaman program dalam situs kami merupakan risiko Anda sendiri.
      </p>
      <p>
        AmazingSedekah tidak menjamin bahwa setiap program yang terdapat pada situs kami akan
        mendapatkan sejumlah donasi tertentu atau akan terpenuhi. AmazingSedekah secara tersirat
        maupun tersurat tidak selalu mendukung penyelenggaraan sebuah program, kecuali terdapat
        perjanjian tertulis terlebih dahulu. Kami dengan tegas menolak kewajiban atau tanggung jawab
        atas kegagalan setiap program atau total donasi yang lembaga tetapkan tidak terpenuhi.
      </p>
      <p>
        <strong>D. Laporan dan Pengaduan</strong>
      </p>
      <p>
        <strong>1.1 </strong>
        &nbsp;Pengunjung Platform dan Pengguna Platform berhak untuk mengajukan Laporan dan/atau
        Pengaduan kepada Penyedia Platform mengenai dugaan dan/atau peristiwa pelanggaran Syarat dan
        Ketentuan ini, termasuk, namun tidak terbatas pada:
      </p>
      <ol>
        <li>
          <p>
            Pengunjung Platform atau Pengguna Platform yang memasukkan data dan informasi yang tidak
            lengkap, tidak benar, menyesatkan dan/atau merupakan pemalsuan;
          </p>
        </li>
        <li>
          <p>
            Pengunjung Platform atau Pengguna Platform yang memasukkan dan mengunggah Konten Yang
            Dilarang;
          </p>
        </li>
        <li>
          <p>
            Lembaga yang menyalahgunakan Dana yang berasal dari Program; dan/atau
          </p>
        </li>
        <li>
          <p>
            Lembaga yang tidak memenuhi atau hanya memenuhi sebagian dari Pelaksanaan Program, atau
            memenuhi Pelaksanaan program tetapi tidak sesuai dengan yang dijanjikan oleh Lembaga
            melalui Platform.
          </p>
        </li>
      </ol>
      <p>
        <strong>1.2 </strong>
        &nbsp;Konten Yang Dilarang sebagaimana disebut dalam huruf a butir ii di atas termasuk,
        namun tidak terbatas pada:
      </p>
      <ol>
        <li>
          <p>
            Memuat konten dengan memberikan informasi yang tidak benar;
          </p>
        </li>
        <li>
          <p>
            Memuat konten yang mengandung cyber-bullying;
          </p>
        </li>
        <li>
          <p>
            Memuat konten yang mengganggu (disturbing);
          </p>
        </li>
        <li>
          <p>
            Memuat konten yang mengandung pornografi;
          </p>
        </li>
        <li>
          <p>
            Memuat konten yang mengandung ujaran kebencian dan SARA;
          </p>
        </li>
        <li>
          <p>
            Memuat konten yang copyright/IP dimiliki pihak lain tanpa izin;
          </p>
        </li>
        <li>
          <p>
            Menggalang dana untuk politik praktis;
          </p>
        </li>
        <li>
          <p>
            Menggalang dana tanpa seizin Penerima Manfaat;
          </p>
        </li>
        <li>
          <p>
            Menggalang dana untuk tujuan yang dapat menganggu ketertiban umum;
          </p>
        </li>
        <li>
          <p>
            Menggalang dana untuk bayar hutang pinjaman;
          </p>
        </li>
        <li>
          <p>
            Berkaitan dengan zakat dan qurban;
          </p>
        </li>
        <li>
          <p>
            Menggalang dana dengan konten dan tujuan untuk keperluan tersier.
          </p>
        </li>
      </ol>
      <p>
        <strong>1.3 </strong>
        &nbsp;Laporan dan Pengaduan diajukan oleh Pengunjung Platform atau Pengguna Platform kepada
        Penyedia Platform dengan cara mengisi formulir laporan pada halaman program dari lembaga
        yang bersangkutan.
      </p>
      <p>
        <strong>1.4 </strong>
        &nbsp;Dengan mengajukan Laporan dan Pengaduan, Pengunjung Platform atau Pengguna Platform
        menyatakan sepakat dan bersedia untuk dipanggil sebagai saksi untuk dimintakan keterangannya
        dalam rangka pemeriksaan, termasuk, namun tidak terbatas pada, menghadap ke Penyedia
        Platform, instansi terkait, aparat penegak hukum, dan/atau pengadilan.
      </p>
      <p>
        <strong>E. Penonaktifan, Pemutusan dan Penghapusan Akun</strong>
      </p>
      <p>
        <strong>1.1 </strong>
        &nbsp;Pengunjung Platform atau Pengguna Platform berhak untuk mengajukan permohonan
        penonaktifan dan/atau pemutusan dan penghapusan Akun yang terdaftar atas namanya pada
        Platform kepada Penyedia Platform dengan mengirim permintaan melalui AmazingSedekah.com
        disertai alasan-alasannya.
      </p>
      <p>
        <strong>1.2 </strong>
        &nbsp;Pengunjung Platform atau Pengguna Platform berhak untuk mengajukan permohonan
        penonaktifan dan/atau pemutusan dan penghapusan Akun yang terdaftar atas namanya pada
        Platform kepada Penyedia Platform dengan mengirim permintaan melalui AmazingSedekah.com
        disertai alasan-alasannya.
      </p>
      <p>
        <strong>1.3 </strong>
        &nbsp;Penyedia Platform berhak untuk melakukan penonaktifan dan/atau pemutusan dan
        penghapusan Akun dari Pengunjung Platform atau Pengguna Platform apabila terbukti melanggar
      </p>
      <p>
        <strong>F. Batasan Penyimpanan Data</strong>
      </p>
      <p>
        Anda mengetahui bahwa situs memiliki batasan dalam penyediaan layanan kepada seluruh
        pengguna situs, termasuk batasan jangka waktu data atau konten lainnya yang disimpan oleh
        server AmazingSedekah atas nama Anda. Karena server kami memiliki kapasitas maksimal untuk
        menyimpan data seluruh pengguna. Maka dari alasan tersebut, Anda setuju dan memahami bahwa
        kami berhak untuk mengakhiri akun atau lembaga yang sudah tidak aktif dalam jangka waktu
        tertentu, sebelum melakukan hal tersebut kami akan berusaha memberikan pemberitahuan
        terlebih dahulu kepada Anda via email.
      </p>
      <p>
        <strong>G. Syarat Pengguna Situs</strong>
      </p>
      <p>
        <strong>1.1 </strong>
        &nbsp;Berusia 17 tahun atau di antara usia 13 sampai 16 disertai pengawasan orang tua;
      </p>
      <p>
        <strong>1.2 </strong>
        &nbsp;Pengunjung situs ini wajib menyatakan diri sebagai seseorang yang cakap di mata hukum
        sehingga dapat bertanggung jawab atas segala tindakan ataupun kelalaian apabila melanggar
        syarat dan ketentuan ini;
      </p>
      <p>
        <strong>1.3 </strong>
        &nbsp;Tidak diperkenankan bagi pengunjung situs untuk melakukan tindakan yang dapat
        melanggar ketentuan privasi seperti yang diatur dalam kebijakan privasi pada situs ini.
      </p>
      <p>
        <strong>H. Notifikasi</strong>
      </p>
      <p>
        Pengunjung Platform atau Pengguna Platform sepakat dan bersedia untuk menerima segala
        notifikasi melalui media elektronik, termasuk, namun tidak terbatas pada email, layanan
        pesan singkat (short messaging service atau SMS) dan/atau pesan instan (instant messaging)
        yang didaftarkannya pada Platform dan untuk itu memberikan izin kepada Penyedia Platform
        untuk menghubungi Pengunjung Platform atau Pengguna Platform melalui media elektronik
        tersebut
      </p>
      <p>
        <strong>I. Konten Publik</strong>
      </p>
      <p>
        Anda mengetahui bahwa setiap informasi yang Anda tampilkan pada konten atau layanan kami,
        dapat diakses oleh publik, seperti nama, nomer telepon, email dan sosial media. Hal tersebut
        kami lakukan agar terdapat keterbukaan informasi bagi pengguna situs agar tidak dapat
        menimbulkan kecurigaan ataupun prasangka lainnya yang tidak dapat dipertanggungjawabkan.
      </p>
    </div>
  </div>

  <div class="">
    <div class="title">
      <h6>Biaya operasional</h6>
    </div>
    <div class="content">
      <p>
        Dana yang diperoleh dari Program melalui Platform akan dikenakan biaya administrasi:
        Penyedia Platform sebesar 5% (lima persen) sebagai Dana Operasinal Sistem Platform tetap
        berjalan. Amazing Sedekah tidak mengenakan biaya administrasi (0%) untuk zakat, kategori
        bencana alam atau keadaan yang ditetapkan sebagai bencana oleh pemerintah.
      </p>
      <p>
        Contoh: Gempa Palu Donggala, Kebakaran Hutan, Penyebaran Virus Corona/Covid-19 (sesuai
        dengan syarat dan ketentuan yang berlaku);
      </p>
      <p>
        Dalam hal terdapat pajak, retribusi dan/atau pungutan lainnya yang dikenakan oleh peraturan
        perundang-undangan yang berlaku terhadap Dana yang diperoleh dari program, maka Penyedia
        Platform akan menetapkan biaya tambahan sesuai dengan besaran sebagaimana ditentukan
        peraturan perundang-undangan yang bersangkutan.
      </p>
    </div>
  </div>

  <div class="">
    <div class="title">
      <h6>Ketentuan Untuk Lembaga</h6>
    </div>
    <div class="content">
      <p>
        Peringatan: Anda setuju bahwa AmazingSedekah tidak bertanggung jawab atau berkewajiban atas
        penghapusan atau kegagalan Anda dalam menyimpan data atau konten pada akun atau program
        Anda. Selain itu, Anda berkewajiban menjaga kerahasiaan sandi serta keamanan akun Anda
        sendiri. Kami menyarankan Anda untuk senantiasa menjaga kerahasiaan akun Anda dari pihak
        lain, memastikan bahwa Anda selalu ‘log out’ setiap selesai menggunakan layanan pada situs
        AmazingSedekah.
      </p>
      <p>
        Apabila di kemudian hari Anda mendapati bahwa akun Anda disalahgunakan atau diakses oleh
        pihak lain tanpa persetujuan Anda, segera hubungi kami melalui AmazingSedekah.com agar kami
        dapat memproses akun Anda. Melalui persetujuan Anda kami dapat melakukan beberapa tindakan
        untuk melakukan pencegahan penyalahgunaan akun seperti: pembekuan sementara akun,
        penghapusan akun atau pemblokiran akun. Kami tidak bertanggung jawab untuk setiap kerugian
        atau kerusakan yang diakibatkan dari kegagalan Anda untuk mematuhi aturan pada bagian ini.
      </p>
      <p>
        <strong>A. Syarat Menjadi Lembaga</strong>
      </p>
      <p>
        <strong>1.1</strong>
        &nbsp;Badan Hukum, adalah merupakan organisasi/komunitas/yayasan yang memiliki (Nomor Pokok
        Wajib Pajak) NPWP dan Akta Notaris (Bukti Hukum) tentang pendirian
        organisasi/komunitas/yayasan yang bersangkutan;
      </p>
      <p>
        <strong>1.2</strong>
        &nbsp;Bersedia melakukan serangkaian proses verifikasi akun dan kelengkapan lainnya apabila
        diperlukan;
      </p>
      <p>
        <strong>1.3</strong>
        &nbsp;Lembaga menjamin bahwa setiap informasi yang diberikan kepada situs AmazingSedekah
        merupakan data yang benar dan dapat dipertanggung jawabkan.
      </p>
      <p>
        <strong>B. Kewajiban Lembaga</strong>
      </p>
      <p>
        Lembaga wajib untuk:
      </p>
      <p>
        <strong>1.1 </strong>
        &nbsp;Menjamin dan menyatakan bahwa segala informasi yang dimasukkan dan diunggah oleh
        Lembaga ke Platform, termasuk, namun tidak terbatas pada:
      </p>
      <ol>
        <li>
          <p>
            Hubungan antara Lembaga dengan Penerima Manfaat;
          </p>
        </li>
        <li>
          <p>
            Status sebagai bukan pihak yang sedang terlibat dalam proses hukum pidana maupun
            perdata; dan
          </p>
        </li>
        <li>
          <p>
            Status sebagai bukan penyelenggara negara sebagaimana dimaksud dalam Undang-Undang Nomor
            28 Tahun 1999 tentang Penyelenggaraan Negara yang Bersih dan Bebas Dari Korupsi, adalah
            sesuai dengan fakta dan kebenaran yang dapat dipertanggung jawabkan;
          </p>
        </li>
      </ol>
      <p>
        <strong>1.2 </strong>
        &nbsp;Menjamin dan menyatakan bahwa semua Konten yang dimasukkan dan diunggah oleh Lembaga
        ke Platform, tidak memuat Konten Yang Dilarang;
      </p>
      <p>
        <strong>1.3</strong>&nbsp;Menunjuk 1 (satu) rekening bank dari salah satu Mitra Pencairan
        Dana sebagai rekening tujuan dalam rangka Pencairan Dana yang sifatnya tidak dapat diubah,
        kecuali dalam keadaan darurat, dimana Lembaga dapat mengajukan permohonan perubahan data
        kepada Penyedia Platform dengan mengirimkan permintaan melalui AmazingSedekah.com disertai
        alasan-alasannya;
      </p>
      <p><strong>1.4</strong>&nbsp;Dalam hal tujuan Lembaga berbentuk karya atau ide:</p>
      <ol>
        <li><p>Menyatakan bahwa karya atau ide tersebut merupakan karya yang orisinil; atau</p></li>
        <li>
          <p>
            Menyatakan bahwa telah memperoleh izin tertentu apabila bersumber dari karya pihak lain,
            sebagaimana diatur dalam peraturan perundang-undangan tentang hak kekayaan intelektual
            yang berlaku
          </p>
        </li>
      </ol>
      <p>
        <strong>1.5</strong>&nbsp;Sepakat dan bersedia untuk membebaskan Penyedia Platform dari
        setiap gugatan maupun tuntutan hukum, dan untuk mengganti segala kerugian yang mungkin
        dialami oleh Penyedia Platform di kemudian hari atas, namun tidak terbatas pada, hal-hal
        berikut:
      </p>
      <ol>
        <li>
          <p>
            Pelaksanaan program yang tidak dipenuhi, dipenuhi sebagian atau yang dipenuhi tetapi
            tidak sesuai dengan yang dijanjikan oleh lembaga melalui Platform;
          </p>
        </li>
        <li><p>Penggelapan dan/atau penyalahgunaan Dana oleh Lembaga; dan</p></li>
        <li>
          <p>
            Segala perbuatan melanggar hukum lainnya yang terjadi baik pada saat, selama maupun
            setelah masa Program dan/atau Pelaksanaan Program.
          </p>
        </li>
      </ol>
      <p><strong>C.&nbsp;Larangan Pencantuman Rekening Pribadi Maupun Pihak Lain</strong></p>
      <p>
        <strong>1.1</strong>&nbsp;Lembaga dilarang mencantumkan rekening pribadi dari lembaga pada
        halaman Program di Platform.
      </p>
      <p>
        <strong>1.2</strong>&nbsp;Dalam hal Lembaga menerima donasi luar jaringan dari Donatur, dan
        bukan&nbsp;melalui Platform, maka Lembaga sepenuhnya bertanggung jawab&nbsp;atas hubungan
        hukum yang timbul antara Lembaga dan Donatur yang bersangkutan.
      </p>
      <p><strong>D. Kerjasama Khusus</strong></p>
      <p>
        <strong>1.1</strong>&nbsp;Lembaga dapat mengadakan kerjasama khusus dengan Penyedia Platform
        untuk program tertentu yang dituangkan dalam perjanjian terpisah yang mengatur hak dan
        kewajiban para pihak.
      </p>
      <p>
        <strong>1.2</strong>&nbsp;Penyedia Platform memiliki diskresi penuh untuk menyepakati atau
        menolak kerjasama khusus yang diusulkan oleh Lembaga.
      </p>
      <p>
        <strong>1.3</strong>&nbsp;Anda dapat menghubungi kami melalui AmazingSedekah.com untuk
        mendapatkan informasi lebih lanjut.
      </p>
      <p><strong>E. Pelaksanaan Program</strong></p>
      <p>Dalam Pelaksanaan Program, Lembaga berkewajiban untuk:</p>
      <p>
        <strong>1.1</strong>&nbsp;Melaksanakan apa yang telah dijanjikan dalam Program
        melalui&nbsp;Platform, termasuk memenuhi secara tuntas imbalan kepada Donatur&nbsp;dalam hal
        suatu imbalan telah dijanjikan;
      </p>
      <p>
        <strong>1.2</strong>&nbsp;Memberikan laporan Pelaksanaan Program yang
        transparan,&nbsp;kredibel dan dilengkapi dengan dokumentasi dan bukti pendukung&nbsp;yang
        layak kepada Donatur dan Pengelola Platform melalui Platform;
      </p>
      <p>
        <strong>1.3</strong>&nbsp;Dalam hal ada perjanjian kerjasama khusus antara Lembaga dengan
        Penyedia Platform, menjalankan kewajibannya sebagaimana&nbsp;dimuat pada perjanjian
        kerjasama khusus tersebut; dan
      </p>
      <p>
        <strong>1.4</strong>&nbsp;Dalam hal Pelaksanaan Program tidak dapat dipenuhi sesuai
        dengan&nbsp;yang telah dijanjikan dan/atau tidak dapat dipenuhi sama sekali karena
        suatu&nbsp;keadaan memaksa, segera mengajukan laporan kepada Penyedia Platform&nbsp;dengan
        mengirimkan aduan melalui AmazingSedekah.com untuk ditindaklanjuti.
      </p>
      <p><strong>F. Penunjukkan dan Pemberian Kuasa Kepada Penyedia Platform</strong></p>
      <p>
        Lembaga setuju dan sepakat untuk menunjuk dan memberi kuasa&nbsp;kepada Penyedia Platform
        untuk bertindak untuk dan atas nama Lembaga&nbsp;untuk melakukan, antara lain, hal-hal
        berikut:
      </p>
      <p>
        <strong>1.1</strong>&nbsp;Menjadi perantara eksklusif antara Lembaga dengan Donatur dan/atau
        penyebar informasi dari Program yang dilakukan Lembaga;
      </p>
      <p>
        <strong>1.2</strong>&nbsp;Menyimpan dan mengelola Dana dari Donatur yang telah masuk atas
        nama Lembaga;
      </p>
      <p>
        <strong>1.3</strong>&nbsp;Dalam hal terdapat perjanjian kerjasama khusus antara Lembaga
        dengan Penyedia Platform, maka Lembaga dan Penyedia Platform akan bertindak sebagaimana yang
        telah diperjanjikan dalam perjanjian khusus tersebut; dan
      </p>
      <p>
        <strong>1.4&nbsp;</strong>Segala hal yang diperlukan untuk pelaksanaan kuasa di atas,
        termasuk,<br />namun tidak terbatas pada, menandatangani setiap dokumen yang diperlukan,<br />melakukan
        surat-menyurat dengan dan menghadap kepada instansi yang berwenang, notaris dan/atau pejabat
        publik lainnya.
      </p>
    </div>
    <p><strong>G. Penolakan dan Penundaan Verifikasi</strong></p>
    <p>
      Penyedia Platform memiliki kewenangan penuh untuk menolak dan/atau menunda melakukan
      Verifikasi terhadap Akun dan/atau Pencairan Dana apabila Lembaga:
    </p>
    <p>
      <strong>1.1</strong>&nbsp;Belum menyampaikan data-data yang cukup sehubungan dengan
      pendaftaran Akun dan/atau Pencairan Dana kepada Penyedia Platform; atau
    </p>
    <p>
      <strong>1.2</strong>&nbsp;Diduga telah melanggar atau berpotensi melanggar Syarat dan
      Ketentuan.
    </p>
    <p><strong>H. Penutupan Program</strong></p>
    <p>
      <strong>1.1</strong>&nbsp;Penyedia Platform memiliki kewenangan penuh untuk menutup
      Program&nbsp;apabila Lembaga ditemukan telah melanggar Syarat dan Ketentuan.
    </p>
    <p>
      <strong>1.2</strong>&nbsp;Dalam hal Penyedia Platform menutup sebuah Program, Dana yang
      telah&nbsp;masuk ke dalam Rekening kami dari Lembaga bersangkutan akan disalurkan kepada
      Program Acak.
    </p>
    <p><strong>I. Ketentuan Lembaga</strong></p>
    <p>
      <strong>1.1</strong>&nbsp;Lembaga menjamin dan menyatakan bahwa semua konten yang diberikan
      kepada situs AmazingSedekah baik tulisan, foto dan video yang diterangkan dalam deskripsi
      adalah sesuai dengan fakta dan kebenaran yang dapat dipertanggung jawabkan;
    </p>
    <p>
      <strong>1.2</strong>&nbsp;Setiap penggalangan Dana merupakan tanggung jawab penuh dari pihak
      lembaga dan atau fundraiser;
    </p>
    <p>
      <strong>1.3</strong>&nbsp;Penggalangan Dana dilarang untuk dilakukan apabila mengandung unsur:
    </p>
    <ol>
      <li>
        <p>
          Memberikan informasi yang tidak benar dan akurat (contoh: identitas, keaslian dari cerita,
          dll) akan segera ditutup oleh AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang mengandung cyber-bullying, main–main atau bercanda kepada pihak
          tertentu akan segera ditutup oleh AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang mengganggu (disturbing). AmazingSedekah akan menghapus konten yang
          mengandung foto atau video yang tidak sesuai dengan kode etik seperti foto luka yang
          sangat jelas terlihat, seperti, foto korban kecelakaan, dll;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang mengandung pornografi (di kecualikan penggunaan bahasa medis) yang
          sesuai dengan ketentuan daripada Undang-Undang No 44 Tahun 2008 Tentang Pornografi akan
          segera ditutup oleh AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang mengandung ujaran kebencian dan SARA. Penggalangan yang mengujar
          kebencian dan menjelek–jelekkan suatu ras, agama, budaya, orientasi seksual, dan segala
          bentuk lainnya akan segera ditutup oleh AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang copyright/IP dimiliki pihak lain tanpa izin. Pastikan Anda menggunakan
          konten, di mana Anda memiliki hak penuh untuk menggunakannya. Dimohon untuk mengkutip
          sumber asli apabila Anda menggunakan konten dari pihak ketiga (contoh: sumber berita);
        </p>
      </li>
      <li>
        <p>
          Menggalang dana untuk kegiatan politik praktis. AmazingSedekah tidak memfasilitasi
          penggalangan dana untuk tujuan politik praktis seperti memberikan identitas politik pada
          konten halaman galang dana termasuk mengajak memilih partai/kandidat tertentu;
        </p>
      </li>
      <li>
        <p>
          Menggalang dana untuk tujuan yang dapat menganggu ketertiban umum. AmazingSedekah tidak
          memfasilitasi penggalangan dana untuk kegiatan-kegiatan yang berpotensi mengganggu
          keamanan dan ketertiban, seperti kegiatan/acara yang berpotensi menimbulkan konflik di
          masyarakat, mobilisasi massa tanpa landasan hukum dan/atau berindikasi mengganggu keamanan
          dan ketertiban umum;
        </p>
      </li>
      <li>
        <p>
          Menggalang dana tanpa seizin Penerima Manfaat. Penggalangan dana yang dibuat harus seizin
          daripada Penerima Manfaat dan atau wali Penerima Manfaat (apabila dibawah umur atau
          merupakan flora/fauna ) yang didapatkan oleh Penggalang dana, hal tersebut perlu dilakukan
          agar para pihak yang terlibat mendapatkan transparansi informasi;
        </p>
      </li>
      <li>
        <p>
          Menggalang dana untuk utang. Segala bentuk dan macam pembayaran utang selain daripada
          untuk keperluan Medis dan Pendidikan yang mekanisme harus dibayarkan kepada lembaga
          terkait, diibuktikan dengan penerbitan bukti tunggakan dari lembaga terkait;
        </p>
      </li>
      <li>
        <p>
          Berkaitan dengan zakat dan qurban. Segala bentuk penggalangan dana yang berkaitan dengan
          zakat dan qurban hanya dapat dilakukan oleh partner resmi AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Menggalang dana dengan konten dan tujuan untuk keperluan tersier. Segala bentuk
          penggalangan dana yang tujuannya tidak untuk memenuhi kebutuhan hidup primer dan sekunder
          akan segera ditutup oleh AmazingSedekah. Contoh: Galang dana untuk keperluan liburan,
          bersenang-senang dan kepentingan pribadi lainnya.
        </p>
      </li>
    </ol>
    <ol>
      <li>
        <p>
          Memberikan informasi yang tidak benar dan akurat (contoh: identitas, keaslian dari cerita,
          dll) akan segera ditutup oleh AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang mengandung cyber-bullying, main–main atau bercanda kepada pihak
          tertentu akan segera ditutup oleh AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang mengganggu (disturbing). AmazingSedekah akan menghapus konten yang
          mengandung foto atau video yang tidak sesuai dengan kode etik seperti foto luka yang
          sangat jelas terlihat, seperti, foto korban kecelakaan, dll;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang mengandung pornografi (di kecualikan penggunaan bahasa medis) yang
          sesuai dengan ketentuan daripada Undang-Undang No 44 Tahun 2008 Tentang Pornografi akan
          segera ditutup oleh AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang mengandung ujaran kebencian dan SARA. Penggalangan yang mengujar
          kebencian dan menjelek–jelekkan suatu ras, agama, budaya, orientasi seksual, dan segala
          bentuk lainnya akan segera ditutup oleh AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Memuat konten yang copyright/IP dimiliki pihak lain tanpa izin. Pastikan Anda menggunakan
          konten, di mana Anda memiliki hak penuh untuk menggunakannya. Dimohon untuk mengkutip
          sumber asli apabila Anda menggunakan konten dari pihak ketiga (contoh: sumber berita);
        </p>
      </li>
      <li>
        <p>
          Menggalang dana untuk kegiatan politik praktis. AmazingSedekah tidak memfasilitasi
          penggalangan dana untuk tujuan politik praktis seperti memberikan identitas politik pada
          konten halaman galang dana termasuk mengajak memilih partai/kandidat tertentu;
        </p>
      </li>
      <li>
        <p>
          Menggalang dana untuk tujuan yang dapat menganggu ketertiban umum. AmazingSedekah tidak
          memfasilitasi penggalangan dana untuk kegiatan-kegiatan yang berpotensi mengganggu
          keamanan dan ketertiban, seperti kegiatan/acara yang berpotensi menimbulkan konflik di
          masyarakat, mobilisasi massa tanpa landasan hukum dan/atau berindikasi mengganggu keamanan
          dan ketertiban umum;
        </p>
      </li>
      <li>
        <p>
          Menggalang dana tanpa seizin Penerima Manfaat. Penggalangan dana yang dibuat harus seizin
          daripada Penerima Manfaat dan atau wali Penerima Manfaat (apabila dibawah umur atau
          merupakan flora/fauna ) yang didapatkan oleh Penggalang dana, hal tersebut perlu dilakukan
          agar para pihak yang terlibat mendapatkan transparansi informasi;
        </p>
      </li>
      <li>
        <p>
          Menggalang dana untuk utang. Segala bentuk dan macam pembayaran utang selain daripada
          untuk keperluan Medis dan Pendidikan yang mekanisme harus dibayarkan kepada lembaga
          terkait, diibuktikan dengan penerbitan bukti tunggakan dari lembaga terkait;
        </p>
      </li>
      <li>
        <p>
          Berkaitan dengan zakat dan qurban. Segala bentuk penggalangan dana yang berkaitan dengan
          zakat dan qurban hanya dapat dilakukan oleh partner resmi AmazingSedekah;
        </p>
      </li>
      <li>
        <p>
          Menggalang dana dengan konten dan tujuan untuk keperluan tersier. Segala bentuk
          penggalangan dana yang tujuannya tidak untuk memenuhi kebutuhan hidup primer dan sekunder
          akan segera ditutup oleh AmazingSedekah. Contoh: Galang dana untuk keperluan liburan,
          bersenang-senang dan kepentingan pribadi lainnya.
        </p>
      </li>
    </ol>
    <p>
      <strong>1.4</strong>&nbsp;Apabila program penggalangan Dana berbentuk karya/ide, maka lembaga
      menyatakan bahwa ide atau karya tersebut merupakan karya yang orisinil atau dengan izin
      tertentu apabila bersumber dari karya pihak lain sebagaimana yang diatur dalam peraturan
      perundang-undangan tentang Hak Kekayaan Intelektual yang berlaku;
    </p>
    <p>
      <strong>1.5</strong>&nbsp;AmazingSedekah tidak bertanggung jawab atas penyalahgunaan,
      penggunaan tanpa izin, pelanggaran Hak Kekayaan Intelektual yang dilakukan oleh Lembaga;
    </p>
    <p>
      <strong>1.6</strong>&nbsp;Lembaga dilarang mencantumkan nomor rekening pribadi atau lainnya
      dalam program dengan alasan apapun, AmazingSedekah berhak untuk menghapus nomor rekening
      tersebut apabila ketentuan ini dilanggar;
    </p>
    <p>
      <strong>1.7</strong>&nbsp;AmazingSedekah berwenang melakukan sensor/menghapus gambar yang kami
      anggap mengganggu atau tidak pantas untuk diperlihatkan kepada publik;
    </p>
    <p>
      <strong>1.8</strong>&nbsp;Donasi yang diterima dapat dicairkan setelah akun dan rekening bank
      terverifikasi dalam waktu paling cepat 3×24 jam sejak donasi diterima, apabila ada
      keterlambatan, silakan hubungi melalui AmazingSedekah.com;
    </p>
    <p>
      <strong>1.9</strong>&nbsp;Setiap Dana yang masuk merupakan donasi bersih yang telah dipotong
      biaya administrasi;
    </p>
    <p>
      <strong>2.0</strong>&nbsp;Lembaga bertanggung jawab atas apa yang telah ditulis dan dijanjikan
      pada halaman program;
    </p>
    <p>
      <strong>2.1</strong>&nbsp;Setiap proses pencairan Dana akan dikenakan biaya administrasi dari
      mitra pencairan (disbursement) AmazingSedekah;
    </p>
    <p>
      <strong>2.2</strong>&nbsp;Bahwa apabila terdapat pajak dan/atau retribusi dan/atau pungutan
      legal lain terhadap donasi yang diberikan, maka AmazingSedekah dapat menetapkan biaya tambahan
      pada setiap program sesuai dengan besaran yang berlaku;
    </p>
    <p>
      <strong>2.3</strong>&nbsp;Lembaga berkewajiban untuk memenuhi reward/imbalan yang telah
      dijanjikan kepada donatur;
    </p>
    <p>
      <strong>2.4</strong>&nbsp;Lembaga berkewajiban untuk memberikan laporan pelaksanaan atau
      perkembangan program secara transparan melalui fitur “Update” pada situs AmazingSedekah untuk
      diketahui oleh publik dan donatur pada setiap halaman program yang dibuat;
    </p>
    <p>
      <strong>2.5</strong>&nbsp;Lembaga wajib memberikan keterangan atau laporan melalui situs
      AmazingSedekah apabila pelaksanaan program tidak sesuai dengan rencana dan/atau apabila
      terdapat reward/imbalan yang tidak dapat dipenuhi kepada Donatur;
    </p>
    <p>
      <strong>2.6</strong>&nbsp;Apabila terdapat perjanjian kerjasama khusus antara lembaga dengan
      AmazingSedekah, maka kedua belah pihak wajib menjalankan kewajibannya sebagaimana yang telah
      tertera pada perjanjian kerjasama tersebut;
    </p>
    <p>
      <strong>2.7</strong>&nbsp;Lembaga bertanggung jawab sepenuhnya terhadap pelaksanaan program,
      penggunaan Dana donasi, dan hal-hal lain yang terkait dengan program miliknya. Oleh karena
      itu,pembuat program menyatakan dan bersedia mengganti segala kerugian yang dialami oleh
      AmazingSedekah apabila hal tersebut terjadi di kemudian hari, termasuk membebaskan
      AmazingSedekah dari setiap tuntutan hukum yang timbul pada permasalahan yang disebabkan dan
      diakibatkan oleh lembaga.
    </p>
    <p><strong>J. Verifikasi Akun Lembaga</strong></p>
    <p>
      Setiap pembuat program harus melakukan verifikasi akun untuk melakukan pencairan Dana.
      Verifikasi akun perlu dilakukan agar kami memiliki data terkait lembaga yang bertanggung jawab
      atas sebuah Program. Proses verifikasi akun pembuat program akan kami selesaikan dalam kurun
      waktu maksimal 2 hari kerja. Proses verifikasi akun hanya dapat dilakukan secara online
      melalui situs AmazingSedekah.
    </p>
    <p>
      Pada saat proses verifikasi akun, kami akan meminta beberapa data dan berkas kepada lembaga.
      Adapun data dan berkas yang diminta saat proses verifikasi adalah sebagai berikut:
    </p>
    <ol>
      <li>
        <p>
          Akun Organisasi/Yayasan : Scan NPWP atas nama Organisasi/Yayasan, Akta pendirian, kartu
          identitas pemegang akun, nomor rekening atas nama Organisasi/Yayasan, nomor telepon
          pemegang akun dan media sosial. Nomor rekening dan NPWP yang dilampirkan harus atas nama
          yayasan. Apabila diperlukan, AmazingSedekah berhak melakukan proses verifikasi lebih
          lanjut berupa permintaan berkas pendukung tambahan, wawancara online, kunjungan, dan
          bentuk verifikasi lain sesuai dengan jenis dan kategori program. Kami berhak menolak
          verifikasi akun Anda apabila belum memenuhi standar yang telah ditetapkan AmazingSedekah.
        </p>
      </li>
    </ol>
    <p>
      <em>
        AmazingSedekah berhak melakukan investigasi terhadap sebuah atau beberapa Program yang
        dianggap patut untuk ditelusuri lebih lanjut. Maka dari itu, Anda setuju untuk bekerjasama
        dengan baik serta memberikan informasi dengan benar dan sedetail mungkin.
      </em>
    </p>
    <p><strong>K. Pencairan Dana</strong></p>
    <p>
      Anda sebagai Lembaga dapat melakukan pencairan Dana terhadap donasi telah terkumpul pada
      setiap Program anda kapanpun setelah akun dan rekening Anda terverifikasi.
    </p>
    <p>
      Pada proses verifikasi rekening Anda cukup melakukannya sekali untuk setiap halaman Program.
      Namun, hal ini mengakibatkan Anda harus melakukan verifikasi rekening di setiap program yang
      lembaga buat, apabila lembaga memiliki beberapa campaign dalam satu akun.
    </p>
    <p>Adapun syarat untuk melakukan verifikasi rekening, yaitu:</p>
    <p><strong>1.1</strong>&nbsp;Sudah melakukan verifikasi Akun;</p>
    <p><strong>1.2</strong>&nbsp;Program sudah memiliki minimal satu donatur.</p>
    <p>
      Anda dapat memilih ke mana uang donasi akan dicairkan apabila memenuhi kriteria yang telah
      kami tentukan, yaitu:
    </p>
    <ol>
      <li><p>Rekening Lembaga sendiri;</p></li>
      <li>
        <p>
          Rekening pihak ketiga. Yang dimaksud dengan pihak ketiga yaitu penerima manfaat, keluarga
          penerima manfaat, atau yayasan penyalur donasi. Pada ketentuan ini, pembuat Program harus
          menyertakan alasan mengalihkan pencairan ke pihak ketiga dan melampirkan scan identitas
          atas nama yang bersangkutan. Khusus pencairan ke Organisasi/Yayasan, Pembuat Program perlu
          menambahkan lampiran scan NPWP atas nama Organisasi/Yayasan yang bersangkutan.
        </p>
      </li>
      <li>
        <p>
          Rekening mitra penyalur AmazingSedekah. Anda cukup menghubungi kami untuk memproses
          pilihan ini.
        </p>
      </li>
    </ol>
    <p>
      Dana donasi yang terdapat dalam program dapat dialihkan sewaktu-sewaktu kepada pihak lain
      dengan melibatkan kesepakatan antara lembaga dan Amalhsoleh, hal tersebut dapat terjadi
      apabila:
    </p>
    <ol>
      <li>
        <p>
          Penerima manfaat yang hendak mendapatkan manfaat atau bantuan dari program telah meninggal
          dunia;
        </p>
      </li>
      <li>
        <p>
          Penerima Manfaat yang hendak mendapatkan manfaat atau bantuan dari program telah sembuh
          atau menolak mendapatkan santunan;
        </p>
      </li>
      <li>
        <p>
          Dana donasi melebihi target yang direncanakan, sebagian Dana donasi yang lebih tersebut
          dapat diberikan kepada pihak lain;
        </p>
      </li>
      <li>
        <p>
          Lembaga terbukti menggunakan sebagian atau seluruh Dana donasi tanpa persetujuan penerima
          manfaat yang bersangkutan;
        </p>
      </li>
      <li><p>Status Program sedang menjalani proses hukum dengan pihak yang berwajib;</p></li>
      <li>
        <p>Kondisi tertentu yang menurut AmazingSedekah diperlukan pengalihan Dana donasi.</p>
      </li>
    </ol>
    <p>
      <strong>Peringatan</strong>: AmazingSedekah berhak menolak verifikasi rekening, menyarankan
      pilihan verifikasi rekening dan mengubah secara sepihak pilihan rekening yang sudah
      terverifikasi. Penolakan, saran, atau perubahan verifikasi rekening menjadi hak prerogatif
      AmazingSedekah. Perlu kami sampaikan kembali bahwa sebagai Lembaga bertanggung jawab atas
      amanat yang disampaikan sendiri sesuai dengan yang tertera pada konten program.
    </p>
    <p>
      Setelah donasi kami salurkan kepada pihak lembaga, lembaga wajib memberikan laporan penggunaan
      Dana yang nantinya akan terlihat pada fitur update di situs kami. Kami tidak bertanggung jawab
      atas segala hal yang terjadi terhadap uang donasi yang telah dicairkan kepada Lembaga.
    </p>
    <p><strong>L. Kewenangan AmazingSedekah terhadap Program</strong></p>
    <p>
      <strong>1.1</strong>&nbsp;AmazingSedekah berhak untuk melakukan edit/hapus deskripsi
      Program&nbsp;dan deskripsi update apabila pihak lembaga:
    </p>
    <ol>
      <li>
        <p>Mencantumkan no rekening selain daripada atas nama YAYASAN BERSAMA BERAMAL SHOLEH;</p>
      </li>
      <li>
        <p>
          Terbukti melakukan pencatutan nama pihak ke 3 tanpa seizin dan sepengetahuan yang
          bersangkutan baik disadari oleh kami maupun laporan;
        </p>
      </li>
      <li>
        <p>
          Menurut kami mengandung unsur hate speech kepada pihak ke 3 maupun kepada AmazingSedekah;
        </p>
      </li>
    </ol>
    <p>
      <strong>1.2</strong>&nbsp;AmazingSedekah berhak untuk mengalihkan pencairan donasi milik akun
      Program secara sepihak apabila Program:
    </p>
    <ol>
      <li>
        <p>
          Terbukti melakukan penipuan disertai bukti bukti yang kami dapatkan melalui investigasi
          maupun laporan dari publik;
        </p>
      </li>
      <li><p>Permintaan lembaga sendiri;</p></li>
    </ol>
    <p>
      <strong>1.3</strong>&nbsp;AmazingSedekah berhak menolak/menunda verifikasi akun atau
      membekukan akun dan pencairan donasi apabila pihak Lembaga:
    </p>
    <ol>
      <li><p>Belum mengirimkan data-data yang diperlukan oleh AmazingSedekah.com;</p></li>
      <li>
        <p>
          Apabila dana yang terkumpul harus diberikan secara langsung kepada penerima manfaat sesuai
          dengan ketentuan yang berlaku;
        </p>
      </li>
      <li>
        <p>
          Apabila kami memiliki alasan dan bukti bahwa lembaga melanggar ketentuan layanan pada
          situs kami;
        </p>
      </li>
      <li>
        <p>
          Apabila diperlukan dalam rangka untuk memenuhi perintah pengadilan, surat perintah,
          keputusan pemerintah atau ketentuan hukum yang berlaku di Indonesia.
        </p>
      </li>
    </ol>
  </div>

  <div class="">
    <div class="title">
      <h6>Donatur</h6>
    </div>
    <div class="content">
      <p>
        Semua sumbangan dari donatur merupakan risiko dari donatur itu sendiri. Situs kami tidak
        menjamin bahwa setiap program yang berada dalam situs kami bebas dari penipuan dan
        penyalahgunaan. Apabila di kemudian hari Anda meyakini terdapat konten penipuan dan
        penyalahgunaan dana atau kecurigaan lainnya pada program di situs kami, Anda bisa melaporkan
        hal tersebut dengan cara ‘klik’ tombol laporkan yang tersedia pada setiap program.
      </p>
      <p><strong>A. Kewajiban menjadi Donatur</strong></p>
      <p>Donatur wajib:</p>
      <p>
        <strong>1.2 </strong>
        &nbsp;Tidak memberikan informasi tambahan yang palsu dan/atau menyesatkan atas segala
        halaman, tautan, dan berbagai bentuk media lainnya dari program suatu penggalangan Dana di
        AmazingSedekah.com;
      </p>
      <p>
        <strong>1.3 </strong>
        &nbsp;Bertanggung jawab atas donasi yang telah disalurkan dan menyadari konsekuensi bahwa
        lembaga berkemungkinan tidak dapat melaksanakan secara sebagian atau sepenuhnya atas program
        dan/ataureward/imbalan yang telah dijanjikan sebelumnya pada Program tersebut;
      </p>
      <p>
        <strong>1.4 </strong>
        &nbsp;Donatur menyadari bahwa AmazingSedekah hanya bertanggung jawab sebatas penyaluran
        donasi kepada lembaga;
      </p>
      <p>
        <strong>1.5 </strong>
        &nbsp;Donatur wajib mencermati setiap kali hendak melakukan donasi terhadap program, kami
        tidak menyarankan donatur untuk berdonasi kepada suatu program yang mengandung unsur
        ketidakjelasan, kebohongan, mencurigakan, penipuan dan kegiatan yang dilarang oleh negara.
        AmazingSedekah tidak bertanggung jawab atas kelalaian donatur atas hal yang sudah kami
        peringatkan di atas;
      </p>
      <p>
        <strong>1.6 </strong>
        &nbsp;Sepakat untuk tidak mempermasalahkan dan/atau menuntut secara hukum Pihak
        AmazingSedekah atas penyalahgunaan donasi yang dilakukan oleh Pihak Lembaga;
      </p>
      <p>
        <strong>1.7 </strong>
        &nbsp;Donatur bersedia apabila sewaktu-waktu dihubungi oleh AmazingSedekah walaupun tercatat
        sebagai anonim (anonymous donation) untuk keperluan tertentu;
      </p>
      <p>
        <strong>1.8 </strong>
        &nbsp;Tidak melakukan pencucian uang (money laundry) dan/atau menggunakan uang yang berasal
        dari sumber yang tidak sah secara hukum dalam mendonasikan uangnya untuk mendukung ide
        dan/atau program penggalangan Dana yang ada di dalam situs.
      </p>
      <p>
        <strong>B. Ketentuan Donatur</strong>
      </p>
      <p>
        Donatur memahami bahwa:
      </p>
      <p>
        <strong>1.1 </strong>
        &nbsp;Setiap donasi yang masuk ke dalam suatu program akan terpotong biaya administrasi
        sebesar 5%, kecuali untuk kategori bencana alam dan zakat yang diinisiasi oleh organisasi
        terverifikasi dan lembaga resmi yang mana ditetapkan sebesar 0% (nol persen);
      </p>
      <p>
        <strong>1.2 </strong>
        &nbsp;Donasi yang masuk ke AmazingSedekah tanpa memakai kode unik dan tidak melakukan
        konfirmasi (3 hari setelah dana masuk ke rekening AmazingSedekah) akan dicatat sebagai
        donasi umum yang kemudian disalurkan oleh AmazingSedekah ke program lembaga secara acak;
      </p>
      <p>
        <strong>1.3 </strong>
        &nbsp;Penyedia Platform memfasilitasi penyaluran Dana melalui Platform kepada Lembaga
        dan/atau Penerima Manfaat;
      </p>
      <p>
        <strong>1.4 </strong>
        &nbsp;Dana yang diterima akan masuk ke rekening atas nama Lembaga paling lambat 3 (tiga) x
        24 (dua puluh empat) jam sejak Dana yang dialihkan oleh Donatur telah dilakukan Verifikasi
        oleh Penyedia Platform;
      </p>
      <p>
        <strong>1.5 </strong>
        &nbsp;Tanpa mengurangi hak penolakan dan/atau penundaan Verifikasi Akun, Konten dan/atau
        Pencairan Dana dari Penyedia Platform, Dana yang masuk ke rekening atas nama Lembaga
        sepenuhnya menjadi hak dari Penerima Manfaat, yang harus dipertanggungjawabkan dan hanya
        dapat digunakan untuk Pelaksanaan Program.
      </p>
      <p>
        <strong>C. Donasi Anonim dan Donasi Luar Jaringan</strong>
      </p>
      <p>
        <strong>1.1 </strong>
        &nbsp;Donatur memahami dengan berdonasi secara anonim (anonymous donation), maka nama dan
        alamat email Donatur tidak akan terlihat dan/atau timbul di halaman program yang
        bersangkutan, namun kami tetap dapat melihat nama dan alamat email donatur secara lengkap.
      </p>
      <p>
        <strong>1.2 </strong>
        &nbsp;Donatur yang membuat donasi luar jaringan (offline donation) dengan cara mengalihkan
        Dana langsung kepada rekening Lembaga, dan bukan melalui latform, sepakat untuk melepaskan
        dan membebaskan Penyedia Platform dari segala tanggung jawab atas kerugian yang mungkin
        dialaminya sehubungan dengan donasi luar jaringan tersebut.
      </p>
      <p>
        <strong>D. Random Program</strong>
      </p>
      <p>
        Donatur sepakat dalam hal:
      </p>
      <p>
        <strong>1.1 </strong>
        &nbsp;Donatur membuat donasi dengan cara mengalihkan Dana ke Platform tanpa menggunakan kode
        unik yang telah dikirim oleh Penyedia Platform, sehingga dicatat sebagai donasi umum;
        dan/atau
      </p>
      <p>
        <strong>1.2 </strong>
        &nbsp;Sebuah Program ditutup oleh Penyedia Platform sebab Lembaga bersangkutan melanggar,
        diduga melanggar atau berpotensi melanggar Syarat dan Ketentuan, maka Penyedia Platform
        berhak untuk menyalurkan Dana yang telah masuk Platform kepada program Acak.
      </p>
      <p>
        <strong>E. Refund</strong>
      </p>
      <p>AmazingSedekah dapat melakukan pengembalian uang donatur apabila:</p>
      <p>
        <strong>1.1</strong>
        &nbsp;Program diberhentikan oleh AmazingSedekah baik dengan atau tanpa persetujuan lembaga
        sesuai dengan standar keamanan dan verifikasi AmazingSedekah.
      </p>
      <p>
        <strong>1.2</strong>
        &nbsp;Permintaan lembaga dan atau penerima manfaat untuk mengembalikan uang donasi kepada
        donatur yang digalangkan secara online melalui situs AmazingSedekah.
      </p>
      <p>
        Perhatian: Ketentuan refund tersebut berlaku hanya apabila permintaan dilakukan sebelum uang
        donasi masuk ke rekening lembaga.
      </p>
      <p>
        <strong>Kekayaan Intelektual</strong>
      </p>
      <p>
        Semua Hak Kekayaan Intelektual dalam situs ini dimiliki oleh AmazingSedekah. Semua informasi
        dan bahan yang tertera pada situs kami seperti : logo, ikon, kode html dan kode lainnya
        dalam situs web ini dilarang untuk dimodifikasi, direproduksi atau diubah dengan cara apapun
        di luar wilayah situs ini tanpa izin yang dinyatakan oleh AmazingSedekah.
      </p>
      <p>
        Apabila melanggar hak-hak ini, kami berhak untuk membuat gugatan perdata untuk jumlah
        keseluruhan kerusakan atau kerugian yang diderita.
      </p>
      <p>Pelanggaran terhadap poin ini bisa termasuk dalam tindak pidana.</p>
      <p><strong>A. Pengecualian dan Pelepasan Tanggungjawab</strong></p>
      <p>
        <strong>1.1</strong>
        &nbsp;AmazingSedekah tidak bertanggung jawab atas segala hal yang dijanjikan pihak lembaga
        galang Dana terhadap penerima manfaat atau donatur apabila terjadi sengketa di antara
        mereka. AmazingSedekah tidak memberikan garansi atas apa yang terjadi di kemudian hari.
      </p>
      <p>
        <strong>1.2</strong>
        &nbsp;AmazingSedekah tidak bertanggung jawab atas ketidakpuasan apabila lembaga dan atau
        penerima manfaat atas penggunaan donasi yang telah digalangkan pada situs kami atau situs
        yang didukung oleh AmazingSedekah.
      </p>
      <p>
        <strong>1.3</strong>
        &nbsp;AmazingSedekah tidak bertanggung jawab atas apa yang terjadi apabila donasi telah
        diberikan kepada lembaga dan atau penerima manfaat seperti pencurian, penggelapan atau
        tindakan apapun yang menyebabkan kehilangan Dana donasi.
      </p>
      <p>
        <strong>1.4</strong>
        &nbsp;Dalam keadaan apapun, Pengguna Platform akan membayar kerugian AmazingSedekah dan/atau
        menghindarkan AmazingSedekah (termasuk petugas, direktur, karyawan, agen, dan lainnya) dari
        setiap biaya kerugian apapun, kehilangan, pengeluaran atau kerusakan yang berasal dari
        tuntutan atau klaim pihak ketiga yang timbul dari pelanggaran Pengguna Platform terhadap
        Aturan Penggunaan AmazingSedekah, dan/atau pelanggaran terhadap hak dari pihak ketiga
      </p>
      <p>
        <strong>1.5</strong>
        &nbsp;AMAZINGSEDEKAH tidak memberikan ganti rugi dalam bentuk apapun atas penggalangan dana
        yang dilakukan pada situs kami.
      </p>
      <p>
        <strong>1.6</strong>
        &nbsp;Apabila di kemudian hari Anda sebagai pengguna situs menemukan atau mendapati bahwa
        terdapat program yang mencurigakan atau berindikasi penipuan, Anda setuju untuk membantu dan
        bekerjasama dengan AmazingSedekah untuk melaporkan kepada kami dan ikut serta membantu
        menyelesaikan perkara tersebut.
      </p>
      <p>
        <strong>B. Hak Cipta dan Merek Dagang</strong>
      </p>
      <p>
        Semua fitur yang terdapat di dalam situs ini adalah milik YAYASAN BERSAMA BERAMAL SHOLEH dan
        dilindungi oleh ketentuan hukum Indonesia yang terdapat dalam Undang-undang HKI (Hak
        Kekayaan Intelektual). Oleh karena itu, pengguna situs tidak diperkenankan untuk
        menyalahgunakan fitur yang terdapat dalam situs ini untuk kepentingan pribadi, kolektif
        maupun komersil.
      </p>
      <p>
        <strong>
          C. Hukum yang Mengatur dan Yurisdiksi
        </strong>
      </p>
      <p>
        Syarat dan Ketentuan ini diatur, ditafsirkan dan dilaksanakan berdasarkan hukum Negara
        Kesatuan Republik Indonesia dan Pengunjung maupun Pengguna Platform menundukkan diri pada
        yurisdiksi Badan Abritase Nasional Indonesia (BANI).
      </p>
      <p>
        Setiap orang dengan sengaja, dan tanpa hak menyebarkan berita bohong dan menyesatkan yang
        mengakibatkan kerugian konsumen dalam Transaksi Elektronik akan terjerat ketentuan pidana
        yang tertulis pada Pasal 28 Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan
        Transaksi Elektronik (UU ITE).
      </p>
      <p>
        <strong>D. Sanksi</strong>
      </p>
      <p>
        Apabila pengguna situs terbukti melanggar syarat dan ketentuan yang telah dibuat baik secara
        sengaja maupun tidak dilakukan secara sebagian maupun secara keseluruhan, maka:
      </p>
      <p>
        <strong>1.1</strong>
        &nbsp;Kami akan memberitahu/notifikasi melalui pesan/email maupun telpon kepada pengguna
        mengenai ketentuan apa yang dilanggar;
      </p>
      <p>
        <strong>1.2</strong>
        &nbsp;Kami akan memberikan sanksi sesuai dengan pelanggaran yang dilakukan oleh pengguna
        situs.
      </p>
      <p>
        <strong>E. Penyelesaian Sengketa dan Keadaan Terpaksa</strong>
      </p>
      <p>
        <strong>1.1</strong>
        &nbsp;Dalam hal terdapat suatu perselisihan atau sengketa yang timbul dari atau sehubungan
        dengan penafsiran atau pelaksanaan dari Syarat dan Ketentuan ini, Pengunjung Platform maupun
        Pengguna Platform sepakat untuk menggunakan segala upaya untuk menyelesaikan perselisihan
        atau sengketa tersebut melalui musyawarah untuk mufakat.
      </p>
      <p>
        <strong>1.2</strong>
        &nbsp;Semua sengketa yang timbul dari dan/atau sehubungan dengan Syarat dan Ketentuan ini
        dan/atau pelaksanaan Syarat dan Ketentuan ini, yang tidak dapat diselesaikan secara
        musyawarah, baik mengenai cidera janji maupun perbuatan melawan hukum, termasuk mengenai
        pengakhiran dan/atau keabsahan Syarat dan Ketentuan ini, akan diselesaikan dan diputus
        melalui BANI di wilayah setempat. Meskipun terdapat suatu perselisihan atau sengketa,
        Pengunjung Platform atau Pengguna Platform harus tetap memenuhi kewajibannya berdasarkan
        Syarat dan Ketentuan ini, tanpa mengurangi keberlakuan keputusan arbitrase final.
      </p>
      <p>
        <strong>1.3</strong>
        &nbsp;Anda memahami dan mengerti bahwa Platform kami dapat berhenti beroperasi dikarenakan
        kejadian di luar kemampuan manusia dan atau tidak dapat dihindarkan seperti terjadi
        peperangan, kerusuhan, kebakaran, bencana alam, permogokan dan bencana lainnya yang
        dinyatakan oleh instansi berwenang.
      </p>
      <p>
        <strong>Penutup</strong>
      </p>
      <p>
        Demikian syarat dan ketentuan penggunaan situs AmazingSedekah, dengan menggunakan situs ini
        maka pengguna dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam
        syarat dan ketentuan ini. Atas perhatian dan kesepakatan Anda, kami sampaikan terimakasih.
      </p>
    </div>
  </div>
    </div>
  </div>
  <Menu />
</template>

<script>
import Navbar from '../components/TransactionComponent/NavbarTransaction.vue'
import Menu from '../components/Home/Menu.vue'
import Skeleton from '../components/Skeleton/Skeleton.vue'

export default {
  components: { Navbar, Menu, Skeleton },
  data(){
    return{
      loading: true,
      content: null,
    }
  },
  mounted() {
    this.getContent()
  },
  methods: {
    getContent() {
      this.loading = true;
      const contentSlug = 'term-condition'
      this.$http
        .get(`${process.env.VUE_APP_API_URL}contents/${contentSlug}`)
        .then(response => {
          if(response.data && response.data.content){
            this.content = response.data.content;
            this.loading = false;
          }
        })
        .catch(error => {
          this.loading = false;
          //   console.log(error.response)
        })
    }
  }
}
</script>
